import React from "react";
import { createBrowserRouter } from "react-router-dom";
// import Auth from "components/auth";
// import LoyihaOfisiPage from "pages/project-office/container/oneApplyInfo";
// import MainPage from "pages/main-page/container";
// import OneApplication from "pages/moliya/components/ariza/components/oneApplication";
// import Petition from "pages/moliya/container/ariza";
// import Funding from "pages/moliya/container/mainSidebar";
// import SkoringMain from "pages/skoring/container";
// import SkoringContainer from "pages/skoring/container/one-skoring";
// import BusinessPlan from "pages/project-office/components/business-plan";

// import RootLayout from "RootLoyout";
// import MainProjectOffice from "pages/project-office/container/main";
// import CoordinatingCommission from "pages/CoordinatingCommission/container/main";
// import { ForStructure } from "pages/moliya/components/for-structure/container";
// import OneApplyCommission from "pages/CoordinatingCommission/components/oneApplyInfoCard.js";
// import MainTableBank from "pages/bank-page/container/main-table";
// import OneApplyInfoForBank from "pages/bank-page/container/apply-info";
// import YuristPage from "pages/yurist-page/container/main";
// import OneApplyYurist from "pages/yurist-page/components/oneApplyInfoCard.js";
// import MainStructure from "pages/moliya/container/for-structure";
// import ViewProject from "pages/moliya/components/for-structure/components/view-project";
// import SignDucument from "pages/moliya/components/for-structure/components/view-project/components/sign-document";
// import ViewDocument from "pages/view-document";
import YopiqLanding from "pages";

export const router = createBrowserRouter([
  {
    path: "",
    // element: <Auth />,
    element: <YopiqLanding />,
  },
  // {
  //   path: "view-document-conclusion-dzypp4r/:id",
  //   element: <ViewDocument />,
  // },
  // {
  //   element: <RootLayout />,
  //   children: [
  //     {
  //       path: "/main",
  //       element: <MainPage />,
  //     },
  //     {
  //       path: "moliyalashtirish",
  //       children: [
  //         {
  //           index: true,
  //           element: <Funding />,
  //         },
  //         {
  //           path: "ariza",
  //           children: [
  //             {
  //               index: true,
  //               element: <Petition />,
  //             },
  //             {
  //               path: ":id",
  //               element: <OneApplication />,
  //             },
  //           ],
  //         },
  //       ],
  //     },
  //     {
  //       path: "structure",
  //       children: [
  //         {
  //           index: true,
  //           element: <MainStructure />,
  //         },
  //         {
  //           path: "view-project/:id",
  //           children: [
  //             {
  //               index: true,
  //               element: <ViewProject />,
  //             },
  //             {
  //               path: "sign-ducument",
  //               element: <SignDucument />,
  //             },
  //           ],
  //         },
  //         {
  //           path: ":id",
  //           element: <ForStructure />,
  //         },
  //       ],
  //     },
  //     {
  //       path: "/loyiha-ofisi",
  //       children: [
  //         {
  //           index: true,
  //           element: <MainProjectOffice />,
  //         },
  //         {
  //           path: ":id",
  //           element: <LoyihaOfisiPage />,
  //         },
  //       ],
  //     },
  //     {
  //       path: "business-plan",
  //       element: <BusinessPlan />,
  //     },
  //     {
  //       path: "skoring",
  //       children: [
  //         {
  //           index: true,
  //           element: <SkoringMain />,
  //         },
  //         {
  //           path: ":id",
  //           element: <SkoringContainer />,
  //         },
  //       ],
  //     },
  //     {
  //       path: "muvofiqlashtirish-komissiyasi",
  //       children: [
  //         {
  //           index: true,
  //           element: <CoordinatingCommission />,
  //         },
  //         {
  //           path: ":id",
  //           element: <OneApplyCommission />,
  //         },
  //       ],
  //     },
  //     {
  //       path: "bank-kreditlash",
  //       children: [
  //         { index: true, element: <MainTableBank /> },
  //         {
  //           path: ":id",
  //           element: <OneApplyInfoForBank />,
  //         },
  //       ],
  //     },
  //     {
  //       path: "yurist-xulosa-page",
  //       children: [
  //         {
  //           index: true,
  //           element: <YuristPage />,
  //         },
  //         {
  //           path: ":id",
  //           element: <OneApplyYurist />,
  //         },
  //       ],
  //     },
  //   ],
  // },
]);
